import { Component, ElementRef, HostBinding, Input, Output, ViewChild, inject } from "@angular/core";
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { AnalyticsService } from '@tytapp/analytics';
import { ApiPoll, ApiPollChoice, ApiPollQuestion, ApiPollQuestionVote, ApiUser, PollsApi } from "@tytapp/api";
import { BillingService } from '@tytapp/billing';
import { PageComponent, sleep } from "@tytapp/common";
import { PaywallDialogComponent } from "@tytapp/common-ui/paywall-dialog/paywall-dialog.component";
import { isClientSide } from '@tytapp/environment-utils';
import { UserAuthMediatorComponent, UserService } from "@tytapp/user";
import { BehaviorSubject } from "rxjs";

interface PendingVote {
    id: number;
    questions: PendingQuestionVote[];
}

interface PendingQuestionVote {
    id: number;
    choices: number[];
}

@Component({
    selector: 'tyt-poll',
    templateUrl: './poll-single.component.html',
    styleUrls: ['./poll-single.component.scss']
})
export class PollSingleComponent extends PageComponent {
    private userService = inject(UserService);
    private billing = inject(BillingService);
    private pollsApi = inject(PollsApi);
    private analytics = inject(AnalyticsService);
    private snackbar = inject(MatSnackBar);
    private elementRef = inject(ElementRef) as ElementRef<HTMLElement>;

    _poll: ApiPoll;
    selectedChoice: number;
    voted = false;

    selectedChoices = new Map<number, number[]>();

    private _pollId: string;

    @Input()
    allowEdit = true;

    @Input()
    expectedType: 'ChooseOnePoll' | 'Petition' = 'ChooseOnePoll';

    @Input()
    mini: boolean;

    @HostBinding('class.mini')
    get miniMode() {
        return this.forceMini ?? this.mini;
    }

    get visible() {
        if (this.hideAfterVote && this.userVote)
            return false;
        if (this.hideBeforeVote && !this.userVote)
            return false;

        return true;
    }
    forceMini: boolean = undefined;

    @Input()
    showSocialButtons = true;

    @Input()
    @HostBinding('class.preview')
    preview: boolean;

    @Input()
    get poll() {
        return this._poll;
    }

    @Input() showResultsByDefault: boolean;
    @Input() hideBeforeVote = false;
    @Input() hideAfterVote = false;
    @Input() linkTitle = true;
    @Input() showImage = true;
    @Input() showStatus = true;
    @Input() showVoteCount = true;
    @Input() showTitle = true;
    @Input() showPollQuestions = true;
    @Input() showPollChoices = true;
    @Input() showVoteButton = true;
    @Input() showContentBadge = true;
    @Input() showDisclaimer = true;
    @Input() showResultIndicator = true;
    @Input() showComments = false;

    @HostBinding('class.hide-after-vote') get isHideAfterVote() {
        return this.hideAfterVote && this.userVote;
    }

    @HostBinding('class.hide-before-vote') get isHideBeforeVote() {
        return this.hideBeforeVote && !this.userVote;
    }

    get totalVotes() {
        if (!this.poll)
            return 0;

        if (this.poll.total_voters)
            return this.poll.total_voters;

        let voteCount = 0;

        if (!this.poll.questions)
            return 0;

        for (let question of this.poll.questions) {
            voteCount = Math.max(voteCount, question.total_votes);
        }

        return voteCount;
    }

    @Output()
    voteChanged = new BehaviorSubject<ApiPollQuestionVote>(null);

    set poll(value) {
        if (this._poll === value)
            return;

        this._poll = value;

        setTimeout(() => this.prepare());
        // if (this.user)
        //   setTimeout(() => this.fetchPoll());
    }

    get pollId() {
        if (!this._poll)
            return undefined;

        return this._poll.id;
    }

    get opensLater() {
        if (this.poll && this.poll.opens_at) {
            if (new Date(this.poll.opens_at).getTime() > Date.now())
                return true;
        }

        return false;
    }

    get closedEarlier() {
        if (this.poll && this.poll.closes_at) {
            if (new Date(this.poll.closes_at).getTime() < Date.now())
                return true;
        }

        return false;
    }

    get openForVoting() {
        if (!this.poll)
            return false;

        if (this.poll.opens_at) {
            if (new Date(this.poll.opens_at).getTime() > Date.now())
                return false;
        }

        if (this.poll.opens_at) {
            if (new Date(this.poll.opens_at).getTime() > Date.now())
                return false;
        }

        if (this.poll.closes_at) {
            if (new Date(this.poll.closes_at).getTime() < Date.now())
                return false;
        }

        return true;


    }
    get commentIdentifier() {
        if (!this.poll)
            return null;
        return `poll_${this.poll.slug}`;
    }

    privacyMode = 'none';
    user: ApiUser;
    async init() {

        if (await this.shell.hasFeature('apps.web.petition_privacy_show_email'))
            this.privacyMode = 'showEmail';
        else if (await this.shell.hasFeature('apps.web.petition_privacy_assurance'))
            this.privacyMode = 'assurance';

        this.subscribe(this.userService.userChanged, user => {
            this.user = user;
            // if (this._poll)
            //   this.fetchPoll();
        });
    }

    showResultsWithoutVoting() {
        this.showResultsWithoutVote = true;
        this.showResults();
    }

    reloading = false;
    resultsReady = false;
    savedChoice;

    async reload() {
        if (this.reloading)
            return;

        this.reloading = true;
        await sleep(1000);
        await this.refetchPoll();
        this.reloading = false;
    }

    fakeVotes = false;

    async refetchPoll() {
        this._poll = await this.pollsApi.getPoll(this.pollId).toPromise();
        if (this._poll)
            this.prepare();
    }

    populateSelectedChoices() {
        this.selectedChoices.clear();
        if (!this.poll?.questions)
            return;

        for (let question of this.poll.questions) {
            this.selectedChoices.set(question.id, question.vote?.choice_ids ?? []);
        }
    }

    /**
     * Fisher-Yates shuffle
     * @see https://stackoverflow.com/a/2450976/1995204
     */
    shuffle(array) {
        let currentIndex = array.length;
        while (currentIndex != 0) {
            let randomIndex = Math.random() * currentIndex | 0;
            currentIndex--;
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex], array[currentIndex]];
        }
    }

    async prepare() {
        await this.userService.ready;

        if (!this.poll)
            return;

        if (this.fakeVotes) {
            for (let question of this.poll.questions) {
                let totalVotes = 0;
                for (let choice of question.choices) {
                    let count = Math.floor(Math.random() * 10000);
                    choice.votes_count = `${count}`;
                    totalVotes += count;
                }

                question.total_votes = totalVotes;
            }
        }

        this.voteChanged.next(this.userVote);
        this.voted = !!this.userVote;
        this.populateSelectedChoices();

        if (this.user && isClientSide()) {
            let pendingVote = this.getPendingVoteForThisPoll();

            if (pendingVote) {
                for (let question of pendingVote.questions) {
                    this.selectedChoices.set(question.id, question.choices);
                }

                let snackbarRef: MatSnackBarRef<any>;

                try {
                    snackbarRef = this.snackbar.open(
                        `Welcome ${this.user.display_name || this.user.profile?.first_name || this.user.username}! `
                        + `Submitting your ${this.verbiage.voteNoun}...`
                    );
                    await this.vote();

                    // Sleep here to allow the page to finish rendering before we try to scroll the user to their vote.
                    await sleep(1000);
                    this.snackbar.open(
                        `Welcome ${this.user.display_name || this.user.profile?.first_name || this.user.username}! `
                        + `Thank you for ${this.verbiage.voting}!`, undefined, { duration: 3000 }
                    );
                    this.elementRef.nativeElement.scrollIntoView({
                        block: 'end'
                    });
                } catch (e) {
                    snackbarRef.dismiss();
                    this.shell.alert(`Uh oh`, `There was a problem recording your ${this.verbiage.voteNoun}. Please try again.`)
                    console.error(`Failed to record pending vote: ${e.message}`);
                }
            }
        }

        for (let question of this.poll.questions) {
            if (question.randomize_choices) {
                if (isClientSide()) {
                    let existingShuffle = localStorage[`tyt:poll_question:${question.id}:shuffle`] as string;
                    if (existingShuffle) {
                        let ids = existingShuffle.split(',').map(x => Number(x));
                        let choices = question.choices;
                        let shuffled = ids.map(id => question.choices.find(x => x.id === id)).filter(x => x);

                        let countHasChanged = choices.length != shuffled.length;
                        let missingChoices = countHasChanged || choices.some(x => !shuffled.find(y => y.id === x.id));
                        let hasExtraChoices = countHasChanged || shuffled.some(x => !choices.find(y => y.id === x.id));

                        // If the stored shuffling no longer matches the choices available on this question,
                        // then we need to reshuffle it.
                        if (!countHasChanged && !missingChoices && !hasExtraChoices) {
                            question.choices = shuffled;
                            continue;
                        }
                    }
                }

                this.shuffle(question.choices);
                localStorage[`tyt:poll_question:${question.id}:shuffle`] = question.choices.map(x => x.id).join(',');
            }
        }

        // TODO: we need server support to show these results
        // if (!this.openForVoting && !this.opensLater && !this.poll.hide_results_after_voting) {
        //     this.showResultsWithoutVoting();
        // }

        if (this.voted && !this.poll.hide_results_after_voting)
            this.showResults();

        if (isClientSide()) {
            setTimeout(() => {
                if (!this.descriptionBox)
                    return;

                let el = this.descriptionBox.nativeElement;
                let maxPx = parseInt(getComputedStyle(el).maxHeight);
                this.showReadMore = (el.clientHeight >= maxPx - 10);
            });
        }

        if(!!this.showResultsByDefault &&
            !this.voted &&
            !this.poll.hide_results_before_voting &&
            !this.poll.hide_results_after_voting) {
                this.showResultsWithoutVoting();
        }
    }

    showShareOptions = false;
    showResultsWithoutVote = false;
    resultsAvailable = false;

    showResults() {
        debugger;
        this.resultsAvailable = true;

        if (isClientSide()) {
            setTimeout(() => this.resultsReady = true, 1000);
        } else {
            this.resultsReady = true;
        }
    }

    changeVote() {
        if (!this.allowInlineVote) {
            this.navigateToPollPage();
            return;
        }

        this.voted = false;
        this.savedChoice = this.selectedChoice;
        this.resultsAvailable = false;
    }

    cancelChangeVote() {
        this.voted = true;
        this.selectedChoice = this.savedChoice;

        if (!this.poll.hide_results_after_voting)
            this.showResults();
    }

    get userVote() {
        return this.poll?.questions.find(x => x.vote)?.vote;
    }

    selectedChoicesForQuestion(questionId: number) {
        return this.selectedChoices.get(questionId);
    }

    isSelected(questionId: number, choiceId: number) {
        if (!this.poll?.questions)
            return false;

        return this.selectedChoices.get(questionId)?.includes(choiceId);
    }

    navigateToPollPage() {
        this.router.navigateByUrl(`/polls/${this.poll.slug ?? this.poll.id}`);
    }

    selectChoice(questionId: number, choiceId: number) {
        if (this.voted || !this.poll)
            return;

        if (!this.allowInlineVote) {
            this.navigateToPollPage();
            return;
        }

        let questionChoices = this.selectedChoices.get(questionId) ?? [];
        let question = this.poll.questions.find(x => x.id === questionId);

        if (question.max_choices_count > 1 && this.isSelected(questionId, choiceId)) {
            // Unselect a choice (when more than one choice is allowed)
            questionChoices = questionChoices.filter(x => x !== choiceId);
        } else {
            // Select a choice
            if (question.max_choices_count === 1) {
                questionChoices = [choiceId];
                this.snackbar.open(
                    `
                        Great choice!
                    `,
                    undefined, {
                    duration: 3000
                }
                );
            } else if (questionChoices.length < question.max_choices_count) {
                questionChoices.push(choiceId);

                if (questionChoices.length === question.max_choices_count) {
                    this.snackbar.open(
                        `
                            Great choice! You've selected the maximum number of options (${question.max_choices_count}).
                            To add another choice, unselect a previously selected choice first.
                        `,
                        undefined,
                        {
                            duration: 3000
                        }
                    );
                } else {
                    this.snackbar.open(
                        `
                            Nice! You can choose up to ${question.max_choices_count - questionChoices.length}
                            more options for this question if you want.
                        `,
                        undefined,
                        {
                            duration: 3000
                        }
                    );
                }
            } else {
                this.snackbar.open(
                    `
                        You've already hit the maximum number of choices for this question (${question.max_choices_count}).
                        You can unselect options by clicking them again.
                    `, undefined, {
                    duration: 3000, panelClass: 'error'
                }
                );
            }
        }

        this.selectedChoices.set(questionId, questionChoices);
    }

    private savePendingVote() {
        if (!isClientSide() || !this.poll)
            return;

        localStorage[`tyt:poll:pending_vote`] = JSON.stringify(<PendingVote>{
            id: Number(this.poll.id ?? 0),
            questions: Array.from(this.selectedChoices.entries()).map(([ id, choices]) => ({
                id,
                choices
            }))
        })
    }

    private getStoredPendingVote() {
        const voteData = localStorage['tyt:poll:pending_vote'];
        if (!voteData)
            return undefined;

        try {
            let vote = JSON.parse(voteData) as PendingVote;

            if (Number(this.poll?.id ?? 0) === vote.id)
                return vote;
        } catch (e) {
            this.logger.error(`Failed to parse pending vote: ${e.stack || e.message || e}`);
            this.logger.error(`Vote data was: ${voteData}`);
            delete localStorage['tyt:poll:pending_vote'];
            return undefined;
        }
    }

    private getPendingVoteForThisPoll() {
        if (!isClientSide())
            return undefined;

        const vote = this.getStoredPendingVote();
        if (Number(this.poll?.id ?? 0) === vote?.id) {
            delete localStorage['tyt:poll:pending_vote'];
            return vote;
        }

        return undefined;
    }

    get verbiage() {
        let voteNoun = 'vote';
        let voteVerb = 'vote';
        let pollNoun = 'poll';
        let sourcePrefix = 'poll';
        let voting = 'voting';

        if (this.poll.type === 'Petition') {
            voteNoun = 'signature';
            voteVerb = 'sign';
            voting = 'signing';
            pollNoun = 'petition';
            sourcePrefix = 'petition';
        }

        return { voteNoun, voteVerb, pollNoun, sourcePrefix, voting };
    }

    async vote() {
        if (this.voted)
            return;

        // Petitions use different verbiage

        if (this.mini && this.poll.type === 'Petition') {
            // If it is a Petition the user is not allowed to sign it from the Home Page, we are redirecting to the petitions page
            this.redirection.go(`/campaigns/${this.poll.slug}`);
            return;
        }

        // More copy based on the above verbiage

        let source = this.pollId ? `${this.verbiage.sourcePrefix}:${this.pollId}` : undefined;
        let genericErrorMessage =
            `Error: Could not record your ${this.verbiage.voteNoun}! `
            + `Please try again and if the issue persists, `
            + `contact support at support@tytnetwork.com`
            ;

        this.resultsReady = false;
        await this.userService.ready;

        let user = this.userService.user;
        if (!user) {
            this.userService.source = source;

            this.savePendingVote();
            this.shell.showDialog(UserAuthMediatorComponent, `Sign in to ${this.verbiage.voteVerb}`);
            return;
        }

        if (this.poll.membership_required && !await this.billing.isEntitled()) {
            this.userService.source = source;
            this.shell.showDialog(PaywallDialogComponent, { noun: this.verbiage.pollNoun });
            return;
        }

        if (!this.voted) {
            if (this.poll.type === 'Petition') {
                let updatedPetition: ApiPoll;
                try {
                    updatedPetition = await this.pollsApi.signPetition(this.poll.slug).toPromise();
                } catch (e) {
                    if (e.json)
                        e = e.json();
                    alert(e.message || e.error || genericErrorMessage);
                    return;
                }

                if (updatedPetition) {
                    this.voted = true;
                    this.poll = updatedPetition;
                    this.populateSelectedChoices();

                    this.analytics.sendBeacons('Petition signed', () => {
                        this.analytics.sendBeaconGA4('signed_petition', {
                            petition_id: updatedPetition.slug ?? updatedPetition.id,
                            petition_title: updatedPetition.title
                        });
                    });
                }
            } else {
                let updatedPoll: ApiPoll;
                try {
                    updatedPoll = await this.pollsApi.pollVoteV2(
                        this.poll.slug,
                        {
                            questions: Array.from(this.selectedChoices.entries()).map(([id, choice_ids]) => ({
                                id,
                                choice_ids
                            }))
                        }
                    ).toPromise();
                } catch (e) {
                    if (e.json)
                        e = e.json();
                    alert(e.message || e.error || genericErrorMessage);
                    return;
                }

                if (updatedPoll) {
                    this.voted = true;
                    this.poll = updatedPoll;
                    this.populateSelectedChoices();

                    this.analytics.sendBeacons('Poll vote', () => {
                        this.analytics.sendBeaconGA4('answered_poll', {
                            poll_id: updatedPoll.slug ?? updatedPoll.id,
                            poll_title: updatedPoll.title
                        });
                    });
                }
            }

            if (!this.poll.hide_results_after_voting)
                this.showResults();
        }
    }

    hideResults() {
        this.resultsAvailable = false;
    }

    identity(item) {
        return item.id;
    }

    getPercentage(count, total) {
        if (total === 0)
            return 0;

        return Math.round(((count * 100) / total) * 100) / 100.0;
    }

    async clearPendingVote() {
        if (!this.poll)
            return;

        let vote = this.getStoredPendingVote();
        if (vote?.id === Number(this.poll.id)) {
            delete localStorage['tyt:poll:pending_vote'];
        }
    }

    async removeVote() {
        await this.pollsApi.clearVote(this.poll.slug).toPromise();
        await this.clearPendingVote();
        await this.refetchPoll();
    }

    get isVoteValid() {
        if (!this.poll)
            return false;

        if (this.poll.type === 'Petition')
            return true;

        if (!this.openForVoting || !this.poll.questions)
            return false;

        let countChoices = 0;

        for (let question of this.poll.questions) {
            let choices = this.selectedChoices.get(question.id);
            if (!choices)
                continue;
            countChoices += choices?.length ?? 0;

            if (choices.length > question.max_choices_count)
                return false;
        }

        if (countChoices === 0)
            return false;

        return true;
    }

    get votedVerb() {
        if (this.poll && this.poll.type === 'Petition')
            return 'Signed';

        return 'Voted';
    }

    get voteVerb() {
        if (this.poll && this.poll.type === 'Petition')
            return 'Sign this petition';

        return 'Vote';
    }

    get votesLabel() {
        if (this.poll && this.poll.type === 'Petition')
            return 'signatures';

        return 'votes';
    }

    get showChoices() {
        if (this.poll && this.poll.type === 'Petition')
            return false;
        return true;
    }

    get votingOpenLabel() {
        if (this.poll && this.poll.type === 'Petition')
            return 'Sign Now';

        return 'Voting Open';
    }

    /**
     * Defaults to undefined, as mini===true defaults to no description, mini===false
     * defaults to description visible.
     */
    @Input() showDescription: boolean = undefined;
    @Input() allowShortDescription: boolean = undefined;

    get shouldShowDescription() {
        if (this.showDescription === false)
            return false;

        if (this.poll && this.poll.type === 'Petition') {
            return true;
        }

        return this.preview || !this.mini || (this.showDescription === true);
    }

    get useShortDescription() {
        if (this.mini)
            return this.allowShortDescription ?? true;

        return this.allowShortDescription ?? false;
    }

    @ViewChild('descriptionBox')
    descriptionBox: ElementRef<HTMLElement>;

    showReadMore = false;

    get questionsToShow() {
        if (!this.poll)
            return [];

        if (!this.allowInlineVote)
            return this.poll.questions.slice(0, 1);

        return this.poll.questions;
    }

    get allowInlineVote() {
        return !this.mini || this.poll?.questions.length === 1;
    }

    get pollUrl() {
        if (!this.poll)
            return undefined;

        if (this.poll.type === 'Petition')
            return `/campaigns/${this.poll.slug}`;

        return `/polls/${this.poll.slug}`;
    }

    chooseAmountMessageForQuestion(question: ApiPollQuestion) {
        if (question.max_choices_count === 1)
            return `Choose one option.`;
        if (question.max_choices_count === 0)
            return `Choose as many as you'd like.`;
        return `Choose up to ${question.max_choices_count} options.`;
    }

    getChoiceVoteCount(question: ApiPollQuestion, choice: ApiPollChoice) {
        if (question.max_choices_count === 1) {
            return `${this.getPercentage(choice.votes_count, question.total_votes)}%`;
        } else {
            return `${choice.votes_count}`;
        }
    }

    canVoteAndSeeResults(): boolean {
        return this.openForVoting
            && !this.userVote
            && this.poll.hide_results_before_voting
            && !this.poll.hide_results_after_voting
            && this.poll.type !== 'Petition'
            && this.showResultIndicator;
    }
}
